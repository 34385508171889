window.addFields = function(link, association, content, containerSelector, itemSelector) {
  var container = $(containerSelector)
  var new_id = container.find(itemSelector).length
  var regexp = new RegExp("new_"+ association, "g")
  container.append(content.replace(regexp, new_id))
}

window.removeFields = function(link, containerSelector) {
  var container = $(link).parents(containerSelector)
  container.remove()
}

document.addEventListener("DOMContentLoaded", () => {
  $('a[data-submits-form]').click(function(e) {
    e.preventDefault()
    var selector = $(this).attr('href')
    $(selector).submit()
  })
})
