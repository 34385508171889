require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
window.$ = $;
import "../javascript/bootstrap";

import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-pro/js/all";
import "easy-autocomplete/dist/jquery.easy-autocomplete";

import AjaxModal from "../javascript/ajax_modal";
window.AjaxModal = AjaxModal;

import D3AverageRangeBarSingle from "../javascript/d3_charts/d3_average_range_bar_single";
import D3AverageRangeBarMulti from "../javascript/d3_charts/d3_average_range_bar_multi";
import D3HorizontalBarChart from "../javascript/d3_charts/d3_horizontal_bar_chart";
import D3StackedBarChart from "../javascript/d3_charts/d3_stacked_bar_chart";
import D3HorizontalMultiBarChart from "../javascript/d3_charts/d3_horizontal_multi_bar_chart";
window.D3Charts = {
  D3AverageRangeBarSingle: D3AverageRangeBarSingle,
  D3AverageRangeBarMulti: D3AverageRangeBarMulti,
  D3HorizontalBarChart: D3HorizontalBarChart,
  D3HorizontalMultiBarChart: D3HorizontalMultiBarChart,
  D3StackedBarChart: D3StackedBarChart,
};

import "../javascript/easy_autocomplete";
import "../javascript/helpers";
