export default class AjaxModal {

  constructor(content) {
    this.modal = $('#ajax-modal')
    this.content = content
  }

  open() {
    this._reset()
    this._draw('header')
    this._draw('body')
    this._draw('footer')
    this.modal.modal('show')
  }

  _draw(section) {
    if(this.content[section]) {
      let sectionClass = 'modal-'+section
      this.modal.find('.modal-content').append("<div class='"+sectionClass+"'>"+this.content[section]+"</div>")
    }
  }

  _reset() {
    this.modal.find('.modal-content').html('')
  }

}
