document.addEventListener("DOMContentLoaded", () => {

  var bsToggleTriggerText = function(collapse, state) {
    var selector = collapse.data('toggleoptions-triggerselector')
    var trigger = $(selector)
    var text = collapse.data('toggleoptions-'+state)
    if(trigger && text) {
      trigger.html(text)
    }
  }

  $('.collapse[data-toggleoptions-triggerselector]').on('show.bs.collapse', function() {
    bsToggleTriggerText($(this), 'open')
  })

  $('.collapse[data-toggleoptions-triggerselector]').on('hide.bs.collapse', function() {
    bsToggleTriggerText($(this), 'closed')
  })

  // UI shared/copy_text_input
  $('.js-copy-text').on('click', function() {
    var copySelector = $(this).data('text-to-copy')
    var copy = $(copySelector)
    copy.focus()
    copy.select()
    try {
      var success = document.execCommand('copy')
      if(success) {
        $(this).find('span').animate({'opacity': 0}, 500, function() {
          $(this).text('COPIED')
        }).animate({'opacity': 1}, 500)
      }
    } catch {
      console.warn('unable to copy')
    }
  })
  $('.js-copy-text__input').on('blur', function() {
    var id = $(this).attr('id')
    var btn = $(".js-copy-text[data-text-to-copy='#"+id+"']")
    btn.find('span').animate({'opacity': 0}, 500, function() {
      $(this).text('COPY')
    }).animate({'opacity': 1}, 500)
  })

});
