document.addEventListener("DOMContentLoaded", () => {

  var options = {}

  options.templates = {
    district: {
      type: 'custom',
      method: function(value, item) {
        return "<div class='d-flex'><span class='pe-1' style='white-space: nowrap; width: 30px;'>"+(item.state || '')+"</span><span>"+value+"</span></div>"
      }
    }
  }

  options['DefaultSettings'] = function(input, data) {
    return {
      getValue: 'name',
      url: function() {
        return data.url
      },
      ajaxSettings: {
        dataType: 'json',
        method: "GET",
        data: {
          dataType: 'json'
        }
      }
    }
  }

  options['FindDistrict'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      matchResponseProperty: "inputPhrase",
      preparePostData: function(data) {
        search = {autocomplete_for: 'district_name'}
        search.name = $('#search_name').val()
        data.search = search
        return data
      },
      listLocation: 'districts',
      list: {
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          window.location.href = item.url
        }
      },
      template: options.templates.district
    })
  }

  options['State'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'state'}
        search.name = $('#search_name').val()
        project_input = $('#search_project_id')
        if(project_input) {
          search.project_id = project_input.val()
        }
        data.search = search
        return data
      },
      listLocation: 'states',
      list: {
        match: {enabled: true},
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          $('#search_state, #participant_request_state').val(item.abbreviation)
        }
      }
    })
  }

  options['AddSchools'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'name'}
        search.name = $('#search_name').val()
        project_input = $('#search_project_id')
        if(project_input) {
          search.project_id = project_input.val()
        }
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      categories: [
        {
          listLocation: "schools",
          header: "<strong>Schools</strong>"
        },
        {
          listLocation: "districts",
          header: "<strong>Districts</strong>"
        }
      ],
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          var form = input.parents('form')
          if(item && form) {
            selector = '#search_'+item.type+'_id'
            $(selector).val(item.id)
            form.submit()
          }
        }
      },
      template: options.templates.district
    })
  }

  options['ParticipantsSchools'] = function(input, data) {
    return Object.assign(options.AddSchools(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'school_name'}
        search.name = $('#search_school_name').val()
        data.search = search
        return data
      },
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          var form = input.parents('form')
          form.submit()
        }
      }
    })
  }

  options['Participants'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'name'}
        search.name = $('#search_name').val()
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      listLocation: 'participants',
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          input.val(item.value)
          var form = input.parents('form')
          form.submit()
        }
      }
    })
  }

  options['Coordinators'] = function(input, data) {
    return Object.assign(options.Participants(input, data), {
      listLocation: 'coordinators'
    })
  }

  options['RespondentSearchRequestDistrict'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'district_name'}
        search.name = $('#participant_request_autocomplete_district_name').val()
        search.state = $('#participant_request_state').val()
        search.has_active_project = '1'
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      listLocation: 'districts',
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          $('#participant_request_district_id').val(item.id)
          $('#participant_request_district_id').change()
        }
      },
      template: options.templates.district
    })
  }

  options['RespondentSearchRequestSchool'] = function(input, data) {
    return Object.assign(options.DefaultSettings(input, data), {
      preparePostData: function(data) {
        search = {autocomplete_for: 'school_name'}
        search.name = $('#participant_request_autocomplete_school_name').val()
        search.district_id = $('#participant_request_district_id').val()
        search.state = $('#participant_request_state').val()
        search.has_active_project = '1'
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      listLocation: 'schools',
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          $('#participant_request_school_id').val(item.id)
          $('#participant_request_school_id').change()
          $('#participant_request_autocomplete_district_name').val(item.district.name)
          $('#participant_request_district_id').val(item.district.id)
          $('#participant_request_district_id').change()
        }
      },
      template: options.templates.district
    })
  }

  options['Projects'] = function(input, data) {
    return {
      getValue: 'name',
      url: function(q) {
        return data.url
      },
      ajaxSettings: {
        dataType: 'json',
        method: "GET",
        data: {
          dataType: 'json'
        }
      },
      preparePostData: function(data) {
        // this really isn't post but I wanted to send consistent params
        // http://easyautocomplete.com/example/ajax-post
        // maybe there is a better solution
        search = {autocomplete_for: 'name'}
        search.name = $('#search_name').val()
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      listLocation: 'engagements',
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          input.val(item.name)
          var form = input.parents('form')
          form.submit()
        }
      },
      template: {
        type: 'custom',
        method: function(value, item) {
          return "<div class='d-flex'><span class='pe-1' style='white-space: nowrap; width: 30px;'>"+(item.state || '')+"</span><span>"+value+"</span></div>"
        }
      }
    }
  }

  options['Users'] = function(input, data) {
    return {
      getValue: 'name',
      url: function(q) {
        return data.url
      },
      ajaxSettings: {
        dataType: 'json',
        method: "GET",
        data: {
          dataType: 'json'
        }
      },
      preparePostData: function(data) {
        // this really isn't post but I wanted to send consistent params
        // http://easyautocomplete.com/example/ajax-post
        // maybe there is a better solution
        search = {autocomplete_for: 'name'}
        search.name = $('#users_name').val()
        data.search = search
        return data
      },
      matchResponseProperty: "inputPhrase",
      listLocation: 'users',
      list: {
        maxNumberOfElements: 10,
        onChooseEvent: function() {
          var item = input.getSelectedItemData() || {}
          input.val('')

          // Add to list in UI
          const removeLink = "<a href='#' onclick='removeUser(this)' data-user-id='" + item.value + "'>×</a>"
          const hiddenInput = "<input type='hidden' name='users[ids][]' value='" + item.value + "'/>"
          const newListItem = "<li>" + item.name + " " + removeLink + hiddenInput + "</li>"
          $('#users-to-add').html($('#users-to-add').html() + newListItem)
        }
      },
      template: {
        type: 'custom',
        method: function(value, item) {
          return "<div class='d-flex'><span>"+value+"</span></div>"
        }
      }
    }
  }


  $('input[data-easyautocomplete]').each(function() {
    var input = $(this)
    var data = input.data('easyautocomplete')
    inputOptions = options[data.options](input, data)
    input.easyAutocomplete(inputOptions)

  })
})
