import "./helpers"
import Tooltip from "bootstrap/js/dist/tooltip";
import Button from "bootstrap/js/dist/button";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Tab from "bootstrap/js/dist/tab";
import Modal from "bootstrap/js/dist/modal";
import Popover from "bootstrap/js/dist/popover";

window.bootstrap ||= {}

export const initTooltips = (domScope = document) => {
  const tooltipTriggerList = [].slice.call(domScope.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const options = {
    delay: 200,
    boundary: "window"
  };
  tooltipTriggerList.forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, options));
};

export const initPopovers = (domScope = document) => {
  const popoverTriggerList = [].slice.call(domScope.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.forEach((popoverTriggerEl) => new Popover(popoverTriggerEl) )
}


document.addEventListener("DOMContentLoaded", () => {
  initTooltips();
  initPopovers();

  const getModalInstance = (el) => Modal.getInstance(el)
  const getOpenModals = () => {
    return [...document.querySelectorAll('.modal.show')]
  }

  // Initialize js-controlled modals and add event listener to trigger
  const modalTriggerEls = [...document.querySelectorAll('.js-controlled-modal-trigger')]
  modalTriggerEls.forEach((el) => {
    const modalTarget = el.dataset.bsTarget
    if (!modalTarget) return
    const modalEl = document.querySelector(modalTarget)
    if (!modalEl) return
    new Modal(modalEl)
    el.addEventListener('click', (event) => {
      event.preventDefault()
      // Clean up UI in multi-modal scenarios
      getOpenModals()?.map(getModalInstance)?.forEach((modal) => {
        modal.hide()
      })
      getModalInstance(modalEl)?.show()
    })
  })
});

window.bootstrap.Modal = Modal;

export { Button, Collapse, Dropdown, Tab, Modal, Popover };
